import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,2,9];

export const dictionary = {
		"/(public)": [~42,[14],[15]],
		"/(public)/about": [~43,[14],[15]],
		"/(admin)/admin": [~17,[2],[3]],
		"/(admin)/admin/audit": [~18,[2],[3]],
		"/(admin)/admin/clients": [~19,[2],[3]],
		"/(admin)/admin/entities/[id]": [~20,[2],[3]],
		"/(admin)/admin/entity_applications/[application_id=uuid]": [~21,[2],[3]],
		"/(admin)/admin/entity_applications/[application_id=uuid]/[ticker=superstate_fund]": [~22,[2],[3]],
		"/(admin)/admin/organizations": [23,[2],[3]],
		"/(admin)/admin/organizations/[id]": [~24,[2],[3]],
		"/(admin)/admin/users": [~25,[2],[3]],
		"/(admin)/admin/workflows": [26,[2],[3]],
		"/(admin)/admin/workflows/eng": [~27,[2,4],[3]],
		"/(admin)/admin/workflows/eng/api-keys": [~28,[2,4],[3]],
		"/(admin)/admin/workflows/eng/sql_as_a_service": [~29,[2,4],[3]],
		"/(admin)/admin/workflows/eng/sql_as_a_service/[id]": [~30,[2,4],[3]],
		"/(portal)/apply/[id=uuid]": [~36,[9,10]],
		"/(portal)/apply/[id=uuid]/[ticker=superstate_fund]": [~37,[9,10]],
		"/(public)/blog": [44,[14,16],[15]],
		"/(public)/blog/[slug]": [45,[14,16],[15]],
		"/(portal)/dashboard": [~38,[9]],
		"/(portal)/documents": [~39,[9,11]],
		"/(auth)/forgot_password": [~31,[5],[6]],
		"/(public)/platform": [~46,[14],[15]],
		"/(portal)/portfolio": [~40,[9,12]],
		"/(auth)/register": [~32,[5],[6,7]],
		"/(auth)/reset_password": [~33,[5],[6,8]],
		"/(portal)/settings": [~41,[9,13]],
		"/(auth)/signin": [~34,[5],[6]],
		"/(auth)/signout": [35,[5],[6]],
		"/(public)/uscc": [~47,[14],[15]],
		"/(public)/ustb": [~48,[14],[15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';